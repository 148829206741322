// Only with relative paths and named import here, the IntelliSense for component props in add-ons will work correctly.
// Some components should be imported with @loadable/component so they will be combined into separate chunk and loaded on demand when required.
// All other components already have their own chunk or included in webstore initial chunk.
import loadable from '@loadable/component';

export {
  Container,
  Row,
  Col,
} from '../../webstore/components/primitives/grid';

const DateDisplay = loadable(() => import(/*webpackChunkName:"addons-general"*/'../../webstore/components/primitives/time'), { resolveComponent: m => m.DateDisplay });
export {
  DateDisplay,
};

const Button = loadable(() => import(/*webpackChunkName:"addons-general"*/'../../webstore/components/primitives/buttons/addons'), { resolveComponent: m => m.Button });
export {
  Button,
};

const Heading = loadable(() => import(/*webpackChunkName:"addons-general"*/'../../webstore/components/primitives/headings/Heading'));
export {
  Heading,
};

export {
  Link,
  BackLink,
  LinkButton,
} from '../../webstore/components/primitives/links';

export {
  DomBody,
  DomElement,
  DomHead,
} from '../../webstore/components/primitives/dom';

export {
  ProductPrice,
} from '../../webstore/components/primitives/product';

const ProductOrderButton = loadable(() => import(/*webpackChunkName:"addons-general"*/'../../webstore/components/objects/productList/addons'), { resolveComponent: m => m.ProductOrderButton });
export {
  ProductOrderButton,
};

export {
  useModal,
} from '../../webstore/components/objects/modals/addons';

export {
  // ErrorBlock, InfoBlock, AlertBlock, ConfirmBlock names will be deprecated.
  // Names with 'Alert' will be used instead of old names.
  DangerAlert as ErrorBlock,
  InfoAlert as InfoBlock,
  WarningAlert as AlertBlock,
  SuccessAlert as ConfirmBlock,
  DangerAlert,
  InfoAlert,
  WarningAlert,
  SuccessAlert,
} from '../../webstore/components/primitives/alerts';

export {
  LazyImage,
  ResponsiveLazyImage,
} from '../../webstore/components/primitives/responsiveImages';

const StarsRating = loadable(() => import(/*webpackChunkName:"addons-general"*/'../../webstore/components/primitives/rating'), { resolveComponent: m => m.StarsRating });
export {
  StarsRating,
};

export {
  ItemInBasket,
} from '../../webstore/components/objects/itemInBasket';
