import styles from '../MainNav.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ComponentGroup } from 'behavior/navigation/constants';
import DesktopNavBase from './DesktopNavBase';
import NavMenuContext from '../NavMenuContext';
import { Helmet } from 'react-helmet';
import { mobileSafariMediaQuery } from 'utils/detections';
import { mousePointerClassName } from 'components/detection';

const CascadingMainMenuBlock = ({ model, id, isDesignerMode }) => {
  const context = useMemo(() => ({ componentGroup: ComponentGroup.CascadingMainMenu }), []);
  const navId = `MainNav_${id}`;

  return (
    <>
      <Helmet>
        <style>{`
          #${navId} .${styles.hypTopLvl} {
            font-size: ${model.topLevel_FontSize || '20px'};
            color: ${model.topLevel_FontColor || '#000'};
            ${model.topLevel_FontFamily ? `font-family: ${model.topLevel_FontFamily}, sans-serif;` : ''}
            ${model.topLevel_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          html.${mousePointerClassName} #${navId} .${styles.hypTopLvl}:hover {
            color: ${model.topLevel_HoverFontColor || '#000'};
          }
          ${mobileSafariMediaQuery} {
            #${navId} .${styles.hypTopLvl}:hover {
              -webkit-tap-highlight-color: ${model.topLevel_HoverFontColor || '#000'};
            }
          }
          #${navId} .${styles.navListRoot}.nav-is-hovered .${styles.hypTopLvl} {
            color: ${model.topLevel_NonHoveredItemFontColor || 'grey'};
          }
          #${navId} .${styles.navItemRoot} {
            height: ${model.topLevel_ItemHeight || '4.6em'};
          }
          ${model.topLevel_ShowActiveItemUnderline ? `
            #${navId} .${styles.navItemRoot}.${styles.active} .${styles.hypTopLvl} > span:after {
              content: '';
              background-color: ${model.topLevel_ActiveItemUnderlineColor || '#cb2245'};
            }
          ` : ''}
          #${navId} .${styles.navItemRoot}.hover .${styles.hypTopLvl},
          #${navId} .${styles.subItems} {
            background-color: ${model.topLevel_ExpandedMenuBackgroundColor || '#fff'};
            border-color: ${model.topLevel_ExpandedMenuBorderColor || '#e6e6e6'};
          }
          #${navId} .${styles.navItemRoot}:not(.${styles.navItemMega}) .${styles.hypNestedLvl} {
            font-family: ${model.subLevel_FontFamily || '"Open Sans"'}, sans-serif;
            font-size: ${model.subLevel_FontSize || '13px'};
            color: ${model.subLevel_FontColor || '#333'};
            padding-left: 0;
            ${model.subLevel_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          html.${mousePointerClassName} #${navId} .${styles.hypNestedLvl}:hover {
            color: ${model.subLevel_HoverFontColor || '#333'};
          }
          ${mobileSafariMediaQuery} {
            #${navId} .${styles.hypNestedLvl}:hover {
              -webkit-tap-highlight-color: ${model.subLevel_HoverFontColor || '#333'};
            }
          }
          #${navId} .${styles.navItemSub}.hover {
            background-color: ${model.subLevel_HoverBackgroundColor || '#f7f7f7'};
          }
          ${model.subLevel_ShowArrowInFrontOfSubItem ? `
            #${navId} .${styles.hypNestedLvl} {
              padding-left: 0.55em;
            }
            #${navId} .${styles.hypNestedLvl}:before {
              content: '› ';
            }
          ` : ''}
          #${navId} .${styles.navItemMega} .${styles.hypNestedLvl} {
            font-size: ${model.megaMenu_FontSize || '20px'};
            color: ${model.megaMenu_FontColor || '#000'};
            ${model.megaMenu_FontFamily ? `font-family: ${model.megaMenu_FontFamily}, sans-serif;` : ''}
            ${model.megaMenu_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          html.${mousePointerClassName} #${navId} .${styles.navItemMega} .${styles.hypNestedLvl}:hover {
            color: ${model.megaMenu_HoverFontColor || '#333'};
          }
          ${mobileSafariMediaQuery} {
            #${navId} .${styles.navItemMega} .${styles.hypNestedLvl}:hover {
              -webkit-tap-highlight-color: ${model.megaMenu_HoverFontColor || '#333'};
            }
          }
          #${navId} .${styles.navItemMega} .${styles.navItemSub}.hover {
            background-color: ${model.megaMenu_HoverBackgroundColor || '#f7f7f7'};
          }
        `}</style>
      </Helmet>
      <NavMenuContext.Provider value={context}>
        <DesktopNavBase isDesignerMode={isDesignerMode} id={navId} />
      </NavMenuContext.Provider>
    </>
  );
};

CascadingMainMenuBlock.propTypes = {
  model: PropTypes.shape({
    topLevel_FontFamily: PropTypes.string,
    topLevel_FontSize: PropTypes.string,
    topLevel_AllCaps: PropTypes.bool.isRequired,
    topLevel_FontColor: PropTypes.string,
    topLevel_HoverFontColor: PropTypes.string,
    topLevel_NonHoveredItemFontColor: PropTypes.string,
    topLevel_ActiveItemUnderlineColor: PropTypes.string,
    topLevel_ShowActiveItemUnderline: PropTypes.bool.isRequired,
    topLevel_ExpandedMenuBackgroundColor: PropTypes.string,
    topLevel_ExpandedMenuBorderColor: PropTypes.string,
    topLevel_ItemHeight: PropTypes.string,
    subLevel_FontFamily: PropTypes.string,
    subLevel_FontSize: PropTypes.string,
    subLevel_AllCaps: PropTypes.bool.isRequired,
    subLevel_FontColor: PropTypes.string,
    subLevel_HoverFontColor: PropTypes.string,
    subLevel_HoverBackgroundColor: PropTypes.string,
    subLevel_ShowArrowInFrontOfSubItem: PropTypes.bool.isRequired,
    megaMenu_FontFamily: PropTypes.string,
    megaMenu_FontSize: PropTypes.string,
    megaMenu_AllCaps: PropTypes.bool.isRequired,
    megaMenu_FontColor: PropTypes.string,
    megaMenu_HoverFontColor: PropTypes.string,
    megaMenu_HoverBackgroundColor: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default CascadingMainMenuBlock;
