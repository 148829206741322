import type { ClientRoute } from './types';

type Params = {
  id?: string;
  documentType?: string;
  originalOrderId?: string;
};

const documentDetailsRoute = (): ClientRoute => {
  const documentPaths = new Map([
    ['Order', '/profile/orders/'],
    ['BackOrder', '/profile/back-orders/'],
    ['Quote', '/profile/quotes/'],
    ['Invoice', '/profile/invoices/'],
    ['ReturnOrder', '/profile/return-orders/'],
    ['CreditNote', '/profile/credit-notes/'],
    ['ReturnReceipt', '/profile/return-receipts/'],
    ['Shipment', '/profile/shipments/'],
  ]);
  const idSeparator = '__';

  return {
    getVirtualPath(params, context) {
      if (!params)
        paramsAreNotFound();

      const { id, documentType, originalOrderId } = params as Params;
      if (!id || !documentType)
        paramsAreNotFound();

      const path = documentPaths.get(documentType);
      if (!path)
        documentTypeIsNotSupported(documentType);

      const virtualPath = path + context.encodeId(id).toLowerCase();
      if (!originalOrderId)
        return virtualPath;

      return virtualPath + idSeparator + context.encodeId(originalOrderId).toLowerCase();
    },
  };

  function paramsAreNotFound(): never {
    throw new Error('Can\'t build URL for the page. Document ID or type was not specified.');
  }

  function documentTypeIsNotSupported(type: string): never {
    throw new Error(`Can't build URL for the page. Document type '${type}' is not supported.`);
  }
};

export default documentDetailsRoute;
