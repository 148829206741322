import PropTypes from 'prop-types';
import { renderHTML } from 'utils/render';
import { Helmet } from 'react-helmet';
import { getNodeText } from 'components/primitives/dom/helpers';
import { connect, useSelector } from 'react-redux';

const ZendeskChat = ({ zendeskChat }) => {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  if (!zendeskChat?.isActiveChat) {
    return null;
  }

  if (!isAuthenticated && global.window?.$zopim?.livechat) {
    global.window.$zopim.livechat.hideAll();

    return null;
  }

  if (!isAuthenticated) {
    return null;
  }

  const script = [];

  const transformer = node => {

    const { name } = node;
    switch (name) {
      case 'script':
        script.push({
          text: getNodeText(node),
          src: node.attribs.src,
          attributes: node.attribs,
        });
        return;
      default:
        return;

    }
  };

  renderHTML(zendeskChat?.zendeskScript, transformer);

  if (global.window?.$zopim?.livechat?.window && !global.window.$zopim.livechat.window.getDisplay()) {
    global.window.$zopim.livechat.window.hide();
  }

  return (
    <>
      <Helmet>
        {script.map(({ src, text, attributes }, index) => (src
          ? <script src={src} key={`s${index}`} {...attributes} />
          : <script key={`s${index}`} {...attributes}>{text}</script>
        ))}
      </Helmet>
    </>
  );
};

ZendeskChat.propTypes = {
  zendeskChat: PropTypes.shape({
    zendeskScript: PropTypes.string,
    isChatActive: PropTypes.bool
  }),
};

export default connect(({ settings: { zendeskChat } }) => ({ zendeskChat }))(ZendeskChat);
