import { navigateTo } from 'behavior/events';
import { ofType } from 'redux-observable';
import { routesBuilder } from 'routes';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { showStockExceedPopUp, STOCK_EXCEED_APPROVED } from './actions';
import { stockExceedMutation } from './queries';

export default function createEpic() {
  return function (action$, { api }) {
    const stockApprove$ = action$.pipe(
      ofType(STOCK_EXCEED_APPROVED),
      switchMap(() => api.graphApi(stockExceedMutation).pipe(
        switchMap(() => of(showStockExceedPopUp(false), navigateTo(routesBuilder.forCheckout(false, null, false)))),
      )),
    );

    return stockApprove$;
  };
}
