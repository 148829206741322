import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DomHead } from 'components/primitives/dom';

const Injection = ({ htmlInjections }) => {

  if (!htmlInjections || !htmlInjections.head && !htmlInjections.headForLogInUsers)
    return null;

  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  if (isAuthenticated) {
    const concatHeads = htmlInjections.head + htmlInjections.headForLogInUsers;
    return <DomHead content={concatHeads} />;
  }

  else {
    if (!htmlInjections.head)
      return null;

    return <DomHead content={htmlInjections.head} />;
  }
};

Injection.propTypes = {
  htmlInjections: PropTypes.shape({
    head: PropTypes.string,
  }),
};

export default connect(({ settings: { htmlInjections } }) => ({ htmlInjections }))(Injection);