import 'css/Normalize.css';
import 'css/Fonts.scss';
import 'css/General.scss';
import 'css/General.print.scss';

import { memo } from 'react';
import { Layout } from 'layouts';
import { Page } from 'pages';
import { ToastProvider } from 'components/objects/toast';
import { Head, AlternateLinks, CanonicalLink } from 'components/objects/pages';
import {
  HtmlInjectionHead,
  HtmlInjectionBodyStart,
  HtmlInjectionBodyEnd,
} from 'components/objects/htmlInjections';
import { ZendeskChat } from 'components/objects/ZendeskChat';
import { VdPageSwitcher } from 'components/visualDesigner';
import { VdLayoutSwitcher } from 'components/visualDesigner/layout';
import { ErrorBoundary, PreloadErrorComponents } from 'components/objects/error';
import { MatchMediaProvider } from 'components/responsive';
import { MouseDetectionProvider } from 'components/detection';
import { ValidationTextsLoader } from 'components/objects/forms/validation/preload';
import { SanaTextsProvider } from 'components/sanaText';

const App = () => (
  <SanaTextsProvider>
    <MatchMediaProvider>
      <MouseDetectionProvider>
        <ErrorBoundary>
          <Head />
          <HtmlInjectionHead />
          <ZendeskChat />
          <HtmlInjectionBodyStart />
          <CanonicalLink />
          <AlternateLinks />
          <VdLayoutSwitcher>
            <Layout>
              <ToastProvider>
                <VdPageSwitcher>
                  <Page />
                </VdPageSwitcher>
              </ToastProvider>
            </Layout>
            <PreloadErrorComponents />
            <ValidationTextsLoader />
          </VdLayoutSwitcher>
          <HtmlInjectionBodyEnd />
        </ErrorBoundary>
      </MouseDetectionProvider>
    </MatchMediaProvider>
  </SanaTextsProvider>
);

export default memo(App);
