exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ItemInBasket_item-in-basket{line-height:normal;margin:.2em 0;width:100%}", ""]);

// exports
exports.locals = {
	"item-in-basket": "ItemInBasket_item-in-basket",
	"itemInBasket": "ItemInBasket_item-in-basket"
};