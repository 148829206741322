import type { PageComponentNames } from '../componentNames';
import { loadSystemPageQuery } from './queries';
import { initComponent } from 'behavior/pages/helpers';
import { initPageContent } from './helpers';
import { decapitalize } from 'utils/helpers';
import { map, tap } from 'rxjs/operators';
import { RouteData } from 'routes';
import { Handler } from '../types';
import { SystemPageData } from '.';
import { RowContentElementData } from 'behavior/content';

type GenericSystemPage = SystemPageData & { 
  component: PageComponentNames, 
  contactFormTopics: string[] | undefined,
  backTo: any | undefined,
  options: any | undefined,
};

type GenericSystemPageResponse = {
  pages: {
    metaTitle: string | null;
    content: {
        header: RowContentElementData[] | null;
        footer: RowContentElementData[] | null;
    } | null;
  }
  contactFormTopics?: string[];
  backTo?: any,
  options: any | undefined,
}

export default () => {
  let transformedName: string;

  const handler: Handler<RouteData, GenericSystemPage> = ({ routeName, options }, _, { api }) => {
    if (!transformedName)
      transformedName = decapitalize(routeName) as string;

    return api.graphApi<GenericSystemPageResponse>(loadSystemPageQuery(transformedName)).pipe(
      map(({ pages: { metaTitle, content }, contactFormTopics, backTo, options }) => ({ metaTitle, content, contactFormTopics, backTo, options })),
      initComponent(transformedName as PageComponentNames),
      tap(data => {
        if (!data || !data.page)
          return;
        
        data.page = initPageContent(data.page) as GenericSystemPage;
        data.page.options = options;
        data.page.backTo = options && options.backTo;
      }),
    );
  };

  return handler;
};
