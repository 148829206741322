export const enum ShippingAddressOption {
  Billing = 'BILLING',
  Existing = 'EXISTING',
  Custom = 'CUSTOM',
  Pickup = 'PICKUP',
}

export const enum CheckoutProcessNames {
  Order = 'ORDER',
  Quote = 'QUOTE',
  EditOrder = 'EDIT_ORDER',
  EditQuote = 'EDIT_QUOTE',
  Invoice = 'INVOICE',
  Promotion = 'QuotePromotion',
}

export const enum Steps {
  Address = 'ADDRESS',
  Shipping = 'SHIPPING',
  Payment = 'PAYMENT',
  CustomerData = 'ADDITIONAL_CUSTOMER_DATA',
  ExtraPayment = 'EXTRA_PAYMENT',
  Overview = 'OVERVIEW',
  None = 'N/A',
}

export const enum TermsAndAgreementsVisibility {
  Invisible = 'INVISIBLE',
  VisibleNotSelected = 'VISIBLE_NOT_SELECTED',
  VisibleSelected = 'VISIBLE_SELECTED',
}
