import type { State } from '../types';
import { QUICK_ORDER_LINES_RECEIVED, QUICK_ORDER_CLEARED, QuickOrderAction, QuickOrderLinesReceivedAction } from '../actions.quickOrder';
import { createReducer } from 'utils/redux';
import { generateKey } from 'utils/helpers';

export default createReducer<State, QuickOrderAction>(null as unknown as State, {
  [QUICK_ORDER_LINES_RECEIVED]: onLinesReceived,
  [QUICK_ORDER_CLEARED]: onQuickOrderClear,
});

function onLinesReceived(state: State, action: QuickOrderLinesReceivedAction) {
  const productLines = action.payload.lines;
  const lastModifiedLineId = productLines.list.length ? productLines.list[0].id : undefined;
  const list = productLines.list.map(line => {
    const id = line.id || generateKey();
    if ('configuration' in line)
      return { ...line, id };

    const subLines = line.subLines ? line.subLines.map(subLine => ({ ...subLine, id: subLine.id || generateKey() })) : line.subLines;
    return { ...line, id, subLines };
  });

  return {
    ...state,
    lastModifiedLineId,
    model: {
      ...state.model,
      productLines: {
        list,
        totalCount: productLines.totalCount,
      },
      serviceLines: [],
      chargeLines: [],
    },
    isQuickOrderMode: true,
  };
}

function onQuickOrderClear(state: State) {
  return { ...state, isQuickOrderMode: false };
}
