import type { Epic } from 'behavior/types';
import type { StateObservable } from 'redux-observable';
import type { AppState } from 'behavior';
import type { Settings } from './types';
import { EMPTY, merge, of, throwError } from 'rxjs';
import { switchMap, map, catchError, filter, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  Section,
  createQuery,
  cookiebarSection,
  mainSection,
  currencyInfoSection,
  addThisSection,
  navigationSection,
  taxDisplaySection,
  shoppingCartSuggestionsSection,
  searchSection,
  documentsSection,
  productComparisonSpecificationsSection,
  productListPresetSection,
  quickOrderSection,
  checkoutSection,
  cookiebarLinkUrlSection,
  shoppingCartSuggestionsUrlSection,
  zendeskChatSection,
} from './queries';
import { APP_INIT } from 'behavior/app';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { SettingsAction, settingsLoaded, setUpdating } from './actions';
import { storage as cookiebarStorage } from 'behavior/cookiebar';

/**
 * Creates settings section descriptor.
 * @param {object} subQuery part or the setting loading query.
 * @param {Function} [shouldReload] checks whether section should be reloaded. By default - no.
 * @param {Function} [shouldLoad] checks whether section should be loaded. By default - yes.
 * @returns {object} settings section descriptor.
 */
function createSectionDescriptor(subQuery: Section, shouldReload?: (settings: Settings) => boolean, shouldLoad?: () => boolean) {
  return {
    subQuery,
    shouldReload,
    shouldLoad,
  };
}

const sections = [
  createSectionDescriptor(mainSection),
  createSectionDescriptor(cookiebarSection,
    ({ cookiebar }) => cookiebarStorage.shouldBeShown() && (!cookiebar || !!cookiebar.isViewerSpecific),
    () => typeof window === 'undefined' || cookiebarStorage.shouldBeShown(),
  ),
  createSectionDescriptor(currencyInfoSection, _ => true),
  createSectionDescriptor(navigationSection),
  createSectionDescriptor(addThisSection),
  createSectionDescriptor(taxDisplaySection, _ => true),
  createSectionDescriptor(shoppingCartSuggestionsSection),
  createSectionDescriptor(searchSection),
  createSectionDescriptor(documentsSection, _ => true),
  createSectionDescriptor(productComparisonSpecificationsSection),
  createSectionDescriptor(productListPresetSection, _ => true),
  createSectionDescriptor(quickOrderSection, _ => true),
  createSectionDescriptor(checkoutSection, _ => true),
  createSectionDescriptor(zendeskChatSection),
];

type SettingsResponse = {
  settings: Partial<Settings>;
};

type LanguageSpecificSettingsResponse = {
  settings: Pick<Settings, 'cookiebar' | 'shoppingCartSuggestionsPage'>;
};

const settingsEpic: Epic<SettingsAction> = (action$, state$, { api }) => {
  const main$ = action$.pipe(
    ofType(APP_INIT, VIEWER_CHANGED),
    switchMap(_ => {
      const query = getQuery(state$);
      if (!query)
        return EMPTY;

      return api.graphApi<SettingsResponse>(query).pipe(
        map(({ settings }) => settingsLoaded(settings)),
        catchError(e => merge(of(settingsLoaded()), throwError(e))),
        startWith(setUpdating()),
      );
    }),
  );

  const languageSpecific$ = action$.pipe(
    ofType(LANGUAGE_CHANGED),
    filter(_ => state$.value.settings.loaded),
    switchMap(_ => {
      const query = createQuery([cookiebarLinkUrlSection, shoppingCartSuggestionsUrlSection]);

      return api.graphApi<LanguageSpecificSettingsResponse>(query).pipe(
        map(({ settings }) => settingsLoaded(settings)),
        startWith(setUpdating()),
      );
    }),
  );

  return merge(main$, languageSpecific$);
};

export default settingsEpic;

function getQuery(state$: StateObservable<AppState>) {
  const settings = state$.value.settings;
  const filtered = settings.loaded
    ? sections.filter(d => d.shouldReload != null && d.shouldReload(settings))
    : sections.filter(d => d.shouldLoad == null || d.shouldLoad());

  return filtered.length ? createQuery(filtered.map(d => d.subQuery)) : null;
}
