import styles from './ItemInBasket.module.scss';
import { SimpleText } from 'components/sanaText';
import PropTypes from 'prop-types';

const ItemInBasket = ({ isInBasket, className = '' }) => {
  return isInBasket ? <div className={`${styles.itemInBasket} ${className}`}><SimpleText textKey="ItemInBasket" /></div> : null;
};

ItemInBasket.propTypes = {
  isInBasket: PropTypes.bool,
  className: PropTypes.string,
};

export default ItemInBasket;