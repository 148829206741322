import styles from '../MainNav.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import NavItemSubItems from '../NavItemSubItems';
import { MainNavItemPropTypes } from '../PropTypes';
import { shouldRenderNavigation } from '../../helpers';
import { useNavItemEventHandlersData } from '../hooks';

const NavItem = ({
  item: {
    id,
    cssClass,
    title,
    displayAsMegaMenu,
    link,
    children: subItems,
    image,
  },
  isActive,
  ariaSubmenuText,
  skipSubItemsRender = false,
}) => {
  const shouldRenderSubItems = !skipSubItemsRender && shouldRenderNavigation(subItems);
  const { navItemEventHandlers, navItemLinkEventHandlers } = useNavItemEventHandlersData(shouldRenderSubItems);

  const navItemRootClassNames = `${styles.navItemRoot} ${isActive ? styles.active : ''} ${cssClass || ''} ${displayAsMegaMenu ? styles.navItemMega : ''}`;
  const hypContainerStyles = `${styles.hypContainer} ${shouldRenderSubItems ? styles.hasSubItems : ''}`;

  return (
    <li
      className={navItemRootClassNames}
      {...navItemEventHandlers}
      aria-keyshortcuts={shouldRenderSubItems ? 'ArrowRight ArrowLeft' : null}
      data-should-handle-aria={shouldRenderSubItems ? 'true' : null}
      aria-controls={shouldRenderSubItems ? id : null}
    >
      {/* Element should be focusable for proper event handling in Mobile Safari, Legacy MS Edge and IE11 when using laptop with touch screen. */}
      <div className={hypContainerStyles} tabIndex="-1">
        <Link
          {...link}
          className={styles.hypTopLvl}
          {...navItemLinkEventHandlers}
          aria-haspopup={shouldRenderSubItems ? 'true' : null}
        >
          <span>{title}</span>
        </Link>
      </div>
      {shouldRenderSubItems &&
        <NavItemSubItems
          subItems={subItems}
          displayAsMegaMenu={displayAsMegaMenu}
          image={image}
          title={title}
          id={id}
          isDesktop
          ariaSubmenuText={ariaSubmenuText}
        />
      }
    </li>
  );
};

NavItem.propTypes = {
  item: MainNavItemPropTypes,
  isActive: PropTypes.bool,
  ariaSubmenuText: PropTypes.string,
  skipSubItemsRender: PropTypes.bool,
};

export default memo(NavItem);
